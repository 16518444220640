.meetquick-logo {
  transition: 0.4s;
}

.meetquick-input {
  margin-top: 20px;
  min-width: 60vw;
}

@media (max-width: 685px) {
  .meetquick-input {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .schedule-available-text {
    display: none;
  }
}
